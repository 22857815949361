"use strict";

angular.module("informaApp")
    .service("SaveSearchSvc", function (BaseApiService, ConstantsSvc) {
        return {
            allSearchesByUserId: function () {
                return BaseApiService.get('search/all');
            },
            getAllUserSearches() {
                return BaseApiService.get('search/all')
                .then(response => response.data.data);
            },
            getSearchById: function (id) {
                return BaseApiService.get('search', {id: id});
            },
            saveSearch: function (search) {
                return BaseApiService.post('search/save', search);
            },
            updateSearch: function (search) {
                return BaseApiService.post('search/update', search);
            },
            deleteSearch: function (id) {
                return BaseApiService.delete('search/delete?id=' + id);
            },
            generateSharedLink: function (id) {
                return BaseApiService.post('search/shared-link/generate', {id: id})
                .then(function (response) {
                    return response.data.data;
                });
            },
            getSearchBySharedLinkUuid: function (uuid) {
                return BaseApiService.get('search/by-shared-link', {uuid: uuid})
                .then(function (response) {
                    return response.data.data;
                });
            }
        };
    });
